export const QUOTE_MIN_PURCHASE_AMOUNT = 5000;
export const QUOTE_MIN_ASSET_MODEL_YEAR = 1900;
export const QUOTE_NOMINAL_MIN_AMOUNT = 0;
export const QUOTE_NOMINAL_MAX_AMOUNT = 10000000;
export const QUOTE_PERCENTAGE_MIN_AMOUNT = 0;
export const QUOTE_PERCENTAGE_MAX_AMOUNT = 99;
export const QUOTE_MIN_BROKERAGE_ORIGINATION_FEE_AMOUNT = 0;
export const QUOTE_MAX_BROKERAGE_ORIGINATION_FEE_AMOUNT = 900;
export enum QUOTE_ADVANCE_OR_ARREARS {
  ADVANCE = "ADVANCE",
  ARREARS = "ARREARS",
}
export const FINANCE_TYPE_CHATTEL_MORTGAGE = "CHATTEL_MORTGAGE";
