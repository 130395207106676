import { Dictionary } from "../../common/types/Dictionary";

export enum GUARANTOR_LIABILITY_TYPES {
  CREDIT_CARD = "CREDIT_CARD",
  MORTGAGE = "MORTGAGE",
  INVESTMENT_PROPERTY_LOAN = "INVESTMENT_PROPERTY_LOAN",
  VEHICLE_LOAN = "VEHICLE_LOAN",
  PERSONAL_LOAN = "PERSONAL_LOAN",
  OTHER = "OTHER",
}

export const GUARANTOR_LIABILITY_TYPES_LABELS: Dictionary = {
  [GUARANTOR_LIABILITY_TYPES.CREDIT_CARD]: "Credit card",
  [GUARANTOR_LIABILITY_TYPES.MORTGAGE]: "Mortgage",
  [GUARANTOR_LIABILITY_TYPES.INVESTMENT_PROPERTY_LOAN]:
    "Investment property loan",
  [GUARANTOR_LIABILITY_TYPES.VEHICLE_LOAN]: "Vehicle loan",
  [GUARANTOR_LIABILITY_TYPES.PERSONAL_LOAN]: "Personal loan",
  [GUARANTOR_LIABILITY_TYPES.OTHER]: "Other loan",
};
